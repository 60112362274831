<template>
  <b-modal id="patient-profile-modal"
    centered
    hide-header
    hide-footer
  >
    <b-row>
      <b-col cols="3" class="flex">
        <Avatar 
          size="100" 
          :src="picture" 
          :status="status"
        />
      </b-col>
      <b-col >
        <b-row class="row space-between mr-2">
            <h5>{{ name  }}</h5>
            <Close class="close" @click="$bvModal.hide('patient-profile-modal')" />
        </b-row>
        <b-row v-if="birthday">
          <p class="value" v-if="moment().diff(moment(birthday),'days') <= 30 " >
            {{moment(birthday).format('DD/MM/YYYY')}} ({{ moment().diff(moment(birthday),'days') }} dias)
          </p>
          <p class="value" v-else-if="moment().diff(moment(birthday),'month') > 1 && moment().diff(moment(birthday),'month') < 12" >
            {{moment(birthday).format('DD/MM/YYYY')}} ({{ moment().diff(moment(birthday), 'month') }} meses)
          </p>
          <p class="value" v-else-if="!birthday" >
            -
          </p>
          <p class="value" v-else >
            {{moment(birthday).format('DD/MM/YYYY')}} ({{ moment().diff(moment(birthday), 'years') }} anos)
          </p>
        </b-row>
        <hr />
        <b-row>
          <b-button   
            v-if="id"
            class="mt-2" 
            variant="link" 
            @click="goTo(`/pacientes/${id}`)"
          >
            Ver perfil
          </b-button>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  components: {
    Avatar: () => import('../General/Avatar.vue'),
    Close: () => import('@/assets/icons/close.svg'),
  },

  props: {
    id: String,
    name: String,
    birthday: String,
    picture: String,
    status: String,
  },

  methods: {
    goTo(path) {
      this.$router.push(path)
    },
  }
}
</script>

<style lang="scss">
#patient-profile-modal {

  .flex {
    display: flex;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .space-between {
    justify-content: space-between;
  }

  h5 {
    font-weight: 700;
    color: var(--type-active);
  }

  p {
    color: var(--type-active);
    font-weight: 600;
  }

  hr {
    margin: 5px 0 !important;
  }

  .close {
    width: 24px;
    height: 24px;
    fill: var(--neutral-800);
    cursor: pointer;
  }
}
</style>